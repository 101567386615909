<!-- =========================================================================================
    File Name: KnowledgeBase.vue
    Description: Knowledge Base Page

========================================================================================== -->

<template>
  <div id="knowledge-base-page">
    <!-- JUMBOTRON -->
    <div class="main-border mb-8 rounded-lg">
      <div
        class="rounded-lg w-full"
        style="background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
         background-size: cover!important
         box-shadow: 0px 4px 25px 0px rgba(0,0,0,.1);"
      >
        <div class="knowledge-base-jumbotron-content lg:p-12 sm:p-10 p-8 rounded-lg" style="height: 100%">
          <h5 class="font-light text-white ml-1">Study Admin</h5>
          <h1 class="mb-1 text-white font-bold" style="font-size: 2.9rem">Select a Component to Configure</h1>
        </div>
      </div>
    </div>

    <!--- COURSE ACTION BAR START --->
    <div class="vx-row mb-6 match-height">
      <div class="vx-col grid grid-cols-3 gap-8 w-full">
        <vx-card
          class="p-6 cursor-pointer flex items-center justify-center"
          :style="`background-image: linear-gradient(118deg, rgba(var(--vs-success), 0.4), rgba(var(--vs-success), .2)), url(${cardBgImgURL})`"
          @click="$router.push('/a/study/domains')"
        >
          <div class="flex items-center justify-center">
            <feather-icon icon="PackageIcon" svgClasses="h-16 w-16" />
          </div>
          <div class="flex items-center justify-center">
            <h1 class="text-center mt-4">Domain<br />Manager</h1>
          </div>
        </vx-card>
        <vx-card
          class="p-6 cursor-pointer text-white"
          :style="`background-image: linear-gradient(118deg, rgba(var(--vs-primary), 0.4), rgba(var(--vs-primary), .2)), url(${cardBgImgURL})`"
          @click="$router.push('/a/study/sets')"
        >
          <div class="flex items-center justify-center">
            <feather-icon icon="LayersIcon" svgClasses="h-16 w-16" />
          </div>
          <div class="flex items-center justify-center">
            <h1 class="text-center mt-4">Set<br />Manager</h1>
          </div>
        </vx-card>
        <vx-card
          class="p-6 cursor-pointer text-white"
          :style="`background-image: linear-gradient(118deg, rgba(var(--vs-danger), 0.4), rgba(var(--vs-danger), .2)), url(${cardBgImgURL})`"
          @click="$router.push('/a/study/questions')"
        >
          <div class="flex items-center justify-center">
            <feather-icon icon="InboxIcon" svgClasses="h-16 w-16" />
          </div>
          <div class="flex items-center justify-center">
            <h1 class="text-center mt-4">Term<br />Manager</h1>
          </div>
        </vx-card>
      </div>
    </div>
    <!--- COURSE ACTION BAR END --->
  </div>
</template>

<!--suppress ES6ShorthandObjectProperty -->
<script>
export default {
  data() {
    return {
      cardBgImgURL: 'https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/blue-wave-bg.png',
    };
  },
  methods: {},
};
</script>
